.filter-wrapper {
    min-height: 145px;
    width: 290px;
    background-color: rgba(0,174,255,.1);
}
.filter-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.filter-header span {
    color: #ddd;
    font-size: 16px;
}
.filter-content {
    margin: 8px 0px;
}
.filter-delete {
    color: #fff;
    cursor: pointer;
    margin-right: 5px;
}
.filter-delete:hover {
    color: red;
}