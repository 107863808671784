.nav-bar {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    background-color: rgba(11,13,18,.95);
    padding: 16px;
    z-index: 999;
}

.left-side-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.left-side-wrapper span {
    color: #ddd;
    font-size: 18px;
}