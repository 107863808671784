body {margin: 0; padding: 0; font-family: Helvetica, Arial, sans-serif; color: #aaa; font-size: 13px; max-width: 100%; height: 100%; background-color: #050d1d; box-sizing: border-box;}
div {box-sizing: border-box;}

h1 {
  font-size: 40px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid inherit;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none; 
  transition: border 0.3s ease;
}

input:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.5);
}

.is-custom-select {min-width: 250px; text-align: left; }
.is-input {min-width: 250px; min-height: 1.9rem; background: #08253c; color: #fff; border: 1px solid black;}

.react-datepicker {background-color: #08253c !important; border: 1px solid black !important;}
.react-datepicker__navigation--next {border-left-color:white !important;}
.react-datepicker__navigation--previous {border-right-color:white !important;}
.react-datepicker__current-month {color:white !important;}
.react-datepicker__header {background-color: black !important;; border-bottom: 1px solid #aaa !important;}
.react-datepicker__day, .react-datepicker__day-name {color: #aaa !important;}
.react-datepicker__day:hover {color:black !important;}
.react-datepicker__day--selected {background-color: #1e90ff !important; color:white !important;}
.react-datepicker__day--selected:hover {color:white !important;}
.react-datepicker__day--outside-month {color:black !important;}

@media screen and (min-width: 601px) {
	::-webkit-scrollbar {width: 5px;}
	::-webkit-scrollbar-track {background: #03273e;}
	::-webkit-scrollbar-thumb {background: #1e90ff;}
	::-webkit-scrollbar-thumb:hover { background: RoyalBlue;}
}