.wrapper {
    margin: 8px 16px 0;
}

.title {
    padding: 4px;
    text-align: left;
}

.title span {
    margin-right: 4px;
}