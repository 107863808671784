.pane {
    position: relative;
    padding: 20px;
}
.pane:after, .pane:before {
    content: "";
    display: block;
    clear: both;
    height: 0;
}
.pane--adaptive, .pane--adaptiveHg {
    padding-left: 0;
    padding-right: 0;
}
.pane--full {
    padding: 0;
}
.pane--flush {
    padding-top: 0;
    padding-bottom: 0;
}
.pane--adaptiveSpace {
    padding-top: 40px;
    padding-bottom: 40px;
}
.pane-content {
    position: relative;
    text-align: center;
}
.pane-backgroundContainer, .pane-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
}
.pane--backgroundTop .pane-background {
    background-position: top;
}
.home-slidePane {
    height: 430px;
    overflow: hidden;
}
.pane--adaptive .pane-content {
    padding: 0 20px;
}
.pane--adaptiveHg .pane-content {
    padding-left: 20px;
    padding-right: 20px;
}
.home-slidePane .pane-backgroundContainer {
    left: 50%;
    right: auto;
    width: 115vw;
    transform: translateX(-50%);
}
.home-slidePane .pane-content {
    height: 100%;
}

@media screen and (min-width: 480px) {
    .pane--adaptiveHg .pane-content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .pane--adaptive .pane-content {
        padding: 0 30px;
    }
}
@media screen and (min-width: 601px) {
    .pane--adaptiveHg .pane-content {
        padding-left: 40px;
        padding-right: 40px;
    }
    .pane--adaptive .pane-content {
        padding: 0 40px;
    }
    .home-slidePane {
        height: 680px;
    }
}
@media screen and (min-width: 901px) {
    .pane--adaptiveSpace {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
@media screen and (min-width: 1201px) {
    .pane--adaptiveSpace {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pane--adaptive .pane-content {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (min-width: 1601px) {
    .pane--adaptiveHg .pane-content {
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
    }
}