.button {
    display: inline-block;
    position: relative;
    background-color: #0e86ca;
    background-color: #02212e;
    border-radius: 8px;
    border: 1px solid #00aeff;
    border: 1px solid #02212e;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    padding: 8px 18px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
}
.button:disabled {
    cursor: auto;
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(118, 118, 118, 0.3);
}
.button--full {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
.button--small {
    font-size: 15px;
    font-weight: 400;
    padding: 7px 42px;
}
.button--very-small {
    max-width: 125px;
    font-size: 12px;
    font-weight: 400;
    padding: 7px 12px;
}
.button.active {
    background-color: #007bc6;
    background-color: #1e90ff; 
    border: solid 1px #79d4ff;
}
.button:not(:active):not(:disabled):hover,
.button:not(:active):not(:disabled):focus {
    background-color: #007bc6;
    background-color: #1e90ff; 
    color: #ffffff;
    border: solid 1px #79d4ff;
}