.space-adaptive-small {
    height: 20px;
}
.space-adaptive-medium {
    height: 30px;
}
.space-adaptive-large {
    height: 40px;
}

@media screen and (min-width: 901px) {
    .space-adaptive-medium {
        height: 40px;
    }
    .space-adaptive-large {
        height: 60px;
    }
}
@media screen and (min-width: 1201px) {
    .space-adaptive-medium {
        height: 60px;
    }
    .space-adaptive-large {
        height: 80px;
    }
}