.small-loading-wrapper {
    display: flex;
    background: 0 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 2001;
    position: fixed;
    bottom: 5px;
}
.small-loading-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: calc(50% - 500px);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: #02212e;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    border-top: 0;
    width: 1000px;
    padding-bottom: 1px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.small-loading-message, .loading-message {
    color: #bbb;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    font-size: 28px;
}

@media screen and (min-width: 601px) {
    .small-loading-wrapper {
        width: 200px;
        right: 0;
    }
    .small-loading-container {
        padding-top: 15px;
    }
    .small-loading-message {
        margin: 5px;
        font-size: 16px;
    }
}

@media screen and (min-width: 901px) {
    .small-loading-wrapper {
        width: 300px;
    }
}

@media screen and (min-width: 1201px) {
    .small-loading-wrapper {
        width: 400px;
    }
}