.general-description {
    font-size: 16px;
    padding-bottom: 16px;
}
.faq-list-element-question {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 16px;
}
.faq-list-element-answer {
    padding: 4px;
    margin-left: 16px;
    font-size: 16px;
}
.single-page-container {
    max-width: 1600px;
    padding: 20px 10px;
    margin: 0px auto;
    text-align: left;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0px 10px;
    background-color: #041625;
}

.segment {
    width: 100%;
}

.section {
    margin: 16px;
}

.section:not(:last-child) {
    border-bottom: 1px solid #1e4d6b;
}

.section-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list-element-horizontal {
    display: flex;
    flex-direction: row;
    margin: 8px;
}

.list-element-vertical {
    display: flex;
    flex-direction: column;
    margin: 8px;
}

.list-element-name {
    flex-grow: 1;
    font-size: 16px;
}
.list-element-value {
    font-size: 16px;
    text-align: right;
}
.list-element-image {
    padding-top: 16px;
    width: 100%;
}

@media screen and (min-width: 601px) {
    .single-page-container {
        padding: 40px 40px;
    }
	.container {
        padding: 0px 40px;
    }
}

@media screen and (min-width: 901px){
    .single-page-container {
        padding: 40px 40px;
    }
	.container {
        flex-direction: row;
        padding: 0px 20px;
    }
	.segment {
        width: 50%;
    }
	.segment:not(:last-child) {
        border-right: 1px solid #1e4d6b;
    }
}

@media screen and (min-width: 1201px) {
	.container {
        padding: 0px 40px;
    }
}