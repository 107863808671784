.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
}

.button-wrapper {
    display: flex;
    align-items: center;
}