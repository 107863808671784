.section-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 15px;
    background-color: rgba(105, 105, 155, 0.10);
    padding: 8px;
    margin-bottom: 16px;
}

.section-title-container {
    display: flex;
    align-items: center;
}

.section-title {
    margin-right: 4px;
}

@media(min-width: 1601px) {
    .section-wrapper {
        padding: 16px 8px;
        margin-bottom: 32px;
    }
}