.pane-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}
.options-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.options-element {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
}
.options-dropdown {
    padding: 60px 0 30px;
}
.label {
    font-size: 18px;
}
.nav-bar {
    display: flex;
}
.nav-button {
    margin-left: 16px;
}
.filters-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
}
.filter-wrapper {
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: rgba(0,174,255,.1);
    padding-bottom: 4px;
    min-height: 145px;
    width: 290px;
}
.filter-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0px;
}
.filter-content {
    margin: 8px 0px;
}
.filter-content-column {
    margin: 4px 16px 0px;
}
.filter-delete {
    color: #fff;
    cursor: pointer;
    margin-right: 5px;
}
.filter-delete:hover {
    color: black;
}
.filter-slider {
    margin: 16px 16px 0px;
}
.tooltip-icon {
    margin-left: 16px;
}
.tooltip {
    text-align: justify;
}